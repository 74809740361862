import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatExpansionModule,
  MatExpansionPanel,
  MatCard,
  MatTab,
  MatTabGroup,
  MatTabsModule,
  MatIcon,
  MatIconModule,
  MatGridListModule,
  MatGridTile,
  MatGridList,
  MatToolbarModule,
  MatToolbar,
  MatTabContent,
  MatCardModule
} from '@angular/material';


import { IsMobileService } from '../services/is-mobile.service';
import { NgxSpinnerModule, NgxSpinnerComponent } from 'ngx-spinner';
import {
    OverlayComponent
} from './../portfolio-components/overlay/overlay.component';

@NgModule({
    imports: [
    CommonModule,
    MatTabsModule,
    MatIconModule,
    MatGridListModule,
    MatToolbarModule,
    MatExpansionModule,
    MatCardModule,
    NgxSpinnerModule
  ],

  declarations: [
      OverlayComponent
  ],
providers: [IsMobileService],
  exports: [
      MatCard,
      MatTab,
      MatTabGroup,
      MatTabContent,
      MatIcon,
      MatGridList,
      MatGridTile,
      MatToolbar,
      NgxSpinnerComponent,
      OverlayComponent,
      MatExpansionPanel
    ]
})
export class ViewBaseModule {
    public static showOverlay = false;
}
