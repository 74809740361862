import { Component, OnInit, Input, Inject, TemplateRef, ContentChildren } from '@angular/core';
import { PortfolioDynamicViewModel, Enum } from '../../portfolio.types';

@Component({
  selector: 'portfolio-nav-links',
  templateUrl: './nav-links.component.html',
  styleUrls: ['./nav-links.component.scss'
]
})
export class NavLinksComponent extends PortfolioDynamicViewModel implements OnInit {
  public orientationModes: Enum = new Enum({horizontal: 0,  vertical: 1 });
  @Input('data')
  public data: string;

  @Input('orientation')
  public orientation = this.orientationModes.get('horizontal');

  @Input('asListedItems')
  public asListedItems;

  public ngOnInit() {}
}
