import { BrowserModule } from '@angular/platform-browser';

import { NgModule, Inject, Injectable, Provider, ViewChild } from '@angular/core';
import { PortfolioRoutingModule } from './portfolio-routing.module';
import { PortfolioComponent } from './portfolio.component';
import { NavLinkComponent } from './portfolio-components/nav-link/nav-link.component';
import { NavLinksComponent } from './portfolio-components/nav-links/nav-links.component';
import { DataRepositoryService } from './services/data-repository.service';
import { PortfolioDynamicViewModel } from './portfolio.types';
import { ViewData } from './data/view.data';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import {
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatRippleModule
} from '@angular/material';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NavBarComponent } from './portfolio-components/nav-bar/nav-bar.component';
import { ExpandableDetailsComponent } from './portfolio-components/expandable-details/expandable-details.component';
import { ExpandableDetailsModule } from './portfolio-components/expandable-details/expandable-details.module';
import { ContactViewStateService } from './services/contact-view-state.service';
import { ContactComponent } from './portfolio-components/contact/contact.component';
import { IsMobileService } from './services/is-mobile.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatMenuModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatRippleModule,
        MatButtonModule,
        NgxSpinnerModule,
        PortfolioRoutingModule
      ],
  declarations: [
    PortfolioComponent,
    NavLinkComponent,
    NavLinksComponent,
    NavBarComponent
  ],
  providers: [
      IsMobileService,
    {
        provide: 'contactViewStateService',
        useClass: ContactViewStateService
    }, {
        provide: 'viewData',
        useClass: ViewData
    }, {
        provide: 'defaultAppState',
        useValue: 'main'
  }],
  bootstrap: [PortfolioComponent]
})
export class PortfolioModule { }


