import { Injectable } from '@angular/core';
import {
  PortfolioLabeledRoutesMap,
  NavItemDetails
} from '../portfolio.types';

@Injectable({
  providedIn: 'root'
})
export class ViewData {
 // Implemented as Obj however order is preserved during parsing for generagtion of nav links.
  public routes: PortfolioLabeledRoutesMap = {
    home: {
      longLabel: 'Home',
      path: 'home',
      shortLabel: 'Home'
    },
    skills: {
      longLabel: 'Skills',
      path: 'skills',
      shortLabel: 'Skills'
    },
    about: {
        longLabel: 'About',
        path: 'about',
        shortLabel: 'About'
    },
    contact: {
      longLabel: 'Contact',
      path: 'contact',
      shortLabel: 'perm_phone_msg'
    }
  };

  public appData = {
      appTitle: 'Nicholas Abrams',
      navLinks: Object.keys(this.routes).map((key): NavItemDetails => {
        const routeDetails: any = this.routes[key];
        return {
          route: routeDetails.path,
          name: routeDetails.shortLabel
        };
      }),
      view: this.routes
    };
}
