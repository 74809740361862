import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export default class StateManager {
    private subject: Subject<any> = new Subject<any>();

    public set(value: any): void {
        this.subject.next(value);
    }

    public get(): Observable<any> {
        return this.subject.asObservable();
    }

    public clear(): void {
        this.subject.next();
    }
}
