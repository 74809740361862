import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewBaseModule } from './views/view-base.module';

const viewFilepath = 'src/portfolio/views/';

const routes: Routes = [{
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
}, {
    path: 'main',
    redirectTo: '/home',
    pathMatch: 'full'
}, {
  path: 'home',
  loadChildren: viewFilepath + 'home/home.module#HomeModule'
}, {
  path: 'about',
  loadChildren: viewFilepath + 'about/about.module#AboutModule'
}, {
  path: 'skills',
  loadChildren: viewFilepath + 'skills/skills.module#SkillsModule'
}, {
  path: 'contact',
  loadChildren: viewFilepath + 'contact/contact.module#ContactModule'
}];

@NgModule({
  imports: [ViewBaseModule, RouterModule.forRoot(routes)],
  exports: [RouterModule, ViewBaseModule]
})
export class PortfolioRoutingModule { }
