import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PortfolioDynamicViewModel } from './portfolio.types';
import { titleCase } from './../util';
import { ContactViewStateService } from './services/contact-view-state.service';
import { Subscription } from 'rxjs';
import { IsMobileService } from './services/is-mobile.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'portfolio-root',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
/**
 * Creates an instance of portfolio component.
 *
 * @param contactShowHideSvc
 *      Dependancy injectedservice for toggling the state                               of the contact components visibility value.
 *
 * @param data
 *      Static data which is bound to the applications internals.
 *
 * @param defaultAppState
 *      The default inner content by name which is
 *      loaded into the applications contained.
 */
export class PortfolioComponent extends PortfolioDynamicViewModel implements OnDestroy, OnInit {
    private contactViewStateSubscriber: Subscription;
    private isMobileStateSubscriber: Subscription;
constructor(
       public contactViewStateService: ContactViewStateService,
       public isMobileSvc: IsMobileService,
       private spinner: NgxSpinnerService,
       @Inject('viewData') public data: any,
       @Inject('defaultAppState') public defaultAppState: any) {
      super();

      this.data = data.appData;
      this.navLinks = data.appData.navLinks;
      this.portfolioTitle = titleCase(data.appData.appTitle);
      this.componentTitle = titleCase(defaultAppState);
        /**
         *  Get the current state of the contact module,
         *  update the current state accordingly.
         */
        this.contactViewStateSubscriber = contactViewStateService.get().subscribe(val => {
            this.showContactWidget = val;
        });

        this.isMobile = isMobileSvc.isMobile;

        window.onresize = () => {
            this.isMobile = isMobileSvc.isMobile;
        };

        setTimeout(() => {
            this.spinner.hide();
        }, 2000);
    }


  public navLinks: any[];

  public showContactWidget: boolean;

  public isMobile = true;

  public ngOnInit() {
    this.spinner.show();
  }

  public ngOnDestroy() {
    this.contactViewStateSubscriber.unsubscribe();
    this.isMobileStateSubscriber.unsubscribe();
  }
}
