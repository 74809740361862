import { Injectable, Injector, Input, Provider, Inject } from '@angular/core';

export class DataRepositoryService {
  public data: any;
  public getData(nameOrNames: string | string[]): any {
    return Array.isArray(nameOrNames)
      ? this.many(nameOrNames)
      : this.first(nameOrNames);
  }
  public first(name: string = ''): object {
    const data = this.data;

    return data && name && Object.keys(this.data).includes(name)
      ? data[name]
      : data;
  }

  public set(name: string, value: any): void {
    this.data[name] = value;
  }

  public many(names: string[]): object[] {
    return names.map(name => this.first(name));
  }

  /**
   * @method union Find objs matching these keys,
   *               then concat the props and values
   *               as a result.
   * @param names The lookup values to use to form the merged result.
   * @returns A merged result as a single object.
   */
  public union(names: string[]): object {
    // Merge results of many into a single dataset.
    return {...this.many(names)};
  }
}
