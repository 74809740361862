import { Component, Input, OnInit, HostListener } from '@angular/core';
import { IsMobileService } from '../../services/is-mobile.service';

@Component({
  selector: 'portfolio-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent  {
    constructor(public isMobileSvc: IsMobileService) {
        this.isMobile = isMobileSvc.isMobile;
    }
  @Input('navLinks') public navLinks: any[];
  @Input('title') public title: string;
  @Input('isMobile') public isMobile = true;

  @HostListener('window:resize')
  public resize() {
    this.isMobile = this.isMobileSvc.isMobile;
  }
}
