
import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';


@Injectable()
export class IsMobileService {
    constructor(private mediaMatcher: MediaMatcher) {
        this.mediaMatcher = mediaMatcher;
    }

    public mobileWidth = '1000px';

    public get isMobile() {
       return this.mediaMatcher.matchMedia('(max-width: ' + this.mobileWidth + ')').matches;
    }
}
