import { ClassMethod } from '@angular/compiler';
import { iterator } from './portfolio/portfolio.types';

export async function asyncEach(obj: any, objIterator: Function | ClassMethod) {}

export function isSet(inputVal: any) {
  switch (typeof inputVal) {
    case 'undefined':
      return false;
    case 'string':
      return inputVal.length > 0;
    case 'object':
      return Object.keys(inputVal).length > 0;
  }

  if (typeof inputVal === undefined) {
    return false;
  }
}

export const titleCase = (subject: string, omit: string[] = []) => {
    const blacklist = [
        'of',
        'and',
        ...omit
    ];
    let upperCaseRepeated = 0,
        isOmitted = false,
        isAcronym = false,
        result;

    function checkAcronym(char, upperCaseRepeatedLength: any = 3) {

        if (char === char.toUpperCase()) {
            ++upperCaseRepeated;
        }

        return upperCaseRepeated === upperCaseRepeatedLength;
    }

    // Check if the subject is an ancronym or should be omitted.
    result = subject.split(' ').map((word) => {

        if (blacklist.includes(word)) {
            isOmitted = true;
        }

        if (!isOmitted) {
            word.split('').forEach((char) => {
                if (checkAcronym(char)) {
                    isAcronym = true;
                }
            });
        }

        // return word in orig state if not valid for mutation.
        if (isOmitted || isAcronym) {
            return word;
        }

        // At this point, execute the mutation of text casing and return it to the original subject in its new state.
        return (
            word.charAt(0).toUpperCase() + word.substr(1, word.length).toLowerCase()
        );
    });

    return result.join(' ');
};

export default {
    isSet,
    titleCase
};
