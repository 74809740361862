import { Injectable, Inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import StateManager from './state-manager.service';

@Injectable({
  providedIn: 'root'
})
export class ContactViewStateService extends StateManager {
    constructor() {
        super();
    }
  public visible: boolean;
}
