import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'portfolio-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {
  @Input('zIndex') public zIndex = 998;
  @Input('visible') public visible = false;
  @Input('showLoader') public showLoader = false;
  public ngOnInit() {}
}
