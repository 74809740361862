import { Component, OnInit, Input, TemplateRef, ContentChild, Output, EventEmitter, Inject } from '@angular/core';
import { ContactViewStateService } from '../../services/contact-view-state.service';
import { Router } from '@angular/router';
import { ViewStateService } from '../../services/view-state.service';

@Inject(ViewStateService)
@Component({
  selector: 'portfolio-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss']
})
export class NavLinkComponent implements OnInit {
    constructor(
        /**
         * @argument contactViewStateService
         * @access public
         * @type ContactViewStateService
         * @description The injected service which determines as well as
         *              updates the visibility of the contact module globally.
         */
        public contactViewStateService: ContactViewStateService,

        /**
         * @argument router
         * @access public
         * @type Router
         * @description An instance of the router which is used to obtain the
         * current active route.
         */
        public router: Router,

        public viewStateService: ViewStateService) {
            this.connect();
        }

  public showContactWidget = false;

  // The name of the route which is currently visible the viewport.
  public activeRoute: string;

  /**
   * @name primaryAction
   * @type string
   * @description Determines the FAB button from the rest of the routing and
   *              navigation link data.
   */
  @Input()
  private primaryAction: 'contact' = 'contact';

  /**
   * @name name
   * @type string
   * @description Determines the visible text of the link.
   */
  @Input()
  public name: string;

  /**
   * @name route
   * @type string
   * @description Determines the path to send the client to when the link
   *              is selected.
   */
  @Input()
  public route: string;

  /**
   * @method toggleContact
   * @description The method which controls the visiblilty for the contact         *              module.
   */
  public toggleContact(event: Event): void {
  const val = !this.showContactWidget;

  event.preventDefault();
  event.stopImmediatePropagation();

  /**
   * Update the last value with the opposite of what its
   * bool val is at the time of execution.
   */
    this.contactViewStateService.set(true);
  }


  /**
   * @name primaryAction
   * @type string
   * @description Determines the FAB button from the rest of the routing and
   *              navigation link data, based on the value provided compared
   *              to the current value of this instances primaryAction.
   */
  public isPrimaryAction = (subject: string): boolean => {
      return subject === this.primaryAction;
  }

  public makeActive(event: Event, routeName: string): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.viewStateService.set(routeName.replace('/', '').trim());
  }

  public connect(): void {
    this.contactViewStateService.get().subscribe(val => {
        this.showContactWidget = val;
    });

    this.activeRoute = this.viewStateService.activeRouteName;

    this.viewStateService.get().subscribe(val => {
        this.activeRoute = val;
    });
  }

  public ngOnInit() {}
}
