import { DataRepositoryService } from './services/data-repository.service';
import { Inject, Injectable } from '@angular/core';
import { NgClass } from '@angular/common';
import { ViewData } from './data/view.data';
import { routes as routeNames,  } from './portfolio.constants';
import utils from './../util';

export interface IPortfolioViewModel {
  title: string;
  cacheStore: Cache;
  data: any;
  componentTitle?: string;
}

// ToDo: Remove extra dead code here which changes the global title based on the inner state.
export abstract class PortfolioViewModelConfig extends DataRepositoryService {
    protected abstract portfolioTitle: string;
    protected abstract componentTitle: string = routeNames[0];
    public cacheStore: Cache = new Cache();
    get title() {
      return utils.titleCase(this.portfolioTitle);
    }

    set title(viewTitle: string) {
      this.componentTitle = viewTitle;
    }
}

export interface ICache {
  lastUpdatedTimestamp?: any;
}


export class Cache extends DataRepositoryService implements ICache {
  public lastUpdatedTimestamp?: Number;

  private timestampCachedData = (templateData: Cache) => {
    templateData.lastUpdatedTimestamp = new Date().getMilliseconds();

    return templateData;
  }

  public getData() {
    return this.data;
  }

   /**
    * The cache validator to track the last update of each obj of data loaded.
    * Called when the store is updated.
    */
  public set(viewName: string, templateData: Cache): any {
    // Cache the view data in case it is needed in the lifecycle of the app.
    super.set(viewName, this.timestampCachedData(templateData));
  }
}

export interface NavItemDetails {
  route: string;
  name: string;
}

export interface RouteDetails {
  routeData: PortfolioLabeledRoutesMap;
}

export interface PortfolioLabeledRoutesMap {
    home: PortfolioLabeledRouteMap;
    about: PortfolioLabeledRouteMap;
    skills: PortfolioLabeledRouteMap;
    contact: PortfolioLabeledRouteMap;
}

export interface PortfolioLabeledRouteMap {
    path: string;
    shortLabel: string;
    longLabel?: string;
}

export interface IObjectIterator {
   data: any;
   recursive: boolean;
   query?: {
    getFirstMatchByName(): any;
    getFirstMatchByValue(): any;
    getValueByPropName(name: string): any;
    getPropNameByValue(value: any): string;
    getPropNamesByValue(value: any): string[];
    getValueTypeByName(name: string): any;
    getValueTypesByName(name: string): Array<string>;
   };
   update?: {
    setFirstMatchByName(): any;
    setFirstMatchByValue(): any;
    setValueByName(name: string): any;
    setByValue(value: any): string;
    setByValue(value: any): string[];
   };

   find(subject?: string | number): any;
}

export const iterator = (
  searchKey: string | number,
  collection: any | any[] | string): any => {
    const collectionType = typeof collection;
    const collectionIsInvalidType = collectionType !== 'object' && collectionType !== 'string';
    const isIterableCollection = (genericCollection): boolean => {
      let collectionIsArray = false;
      let collectionHasInvalidValue = true;
      let collectionIsStandardObject = false;
      let iterable;

      if (genericCollection) {

      }
        switch (collectionType) {
          // tslint:disable-next-line:no-switch-case-fall-through
          case 'object': {
            if (Array.isArray(collection)) {
              collectionIsStandardObject = false;
              collectionIsArray = true;
              collectionHasInvalidValue = collection.length === 0;
              break;
            } else {
              collectionHasInvalidValue = Object.keys(collection).length === 0;
              collectionIsStandardObject = true;
            }
          }
          break;

          case 'string':
          default: {
            collectionHasInvalidValue = collection.length === 0;
            collectionIsStandardObject = false;
          }
        }

    iterable = !collectionIsInvalidType && !collectionHasInvalidValue;

    if (!iterable) {
      throw new TypeError('The value provided does not appear to be an iterable collection.');
    }

    return iterable;
  };

    const findInArrayOrObject = (requestedKeyName): any => {
      const matches: any[] = [];
      const asyncContractToDataSrc = new Promise(function(resolve) {
      let indexOrKey;

        if (isIterableCollection(collection)) {
          for (indexOrKey in collection) {
            if (collection.hasOwnProperty(indexOrKey)) {
              const keyOrIndexHasValueRangeValue = typeof collection[indexOrKey] !== undefined;

              if (keyOrIndexHasValueRangeValue && indexOrKey === requestedKeyName) {
                matches.push(collection[requestedKeyName]);
              }
            }
          }
          return matches;
        }
      });

      return asyncContractToDataSrc;
    };

    return findInArrayOrObject(searchKey);
  };

export abstract class ObjectIteratorBase extends Cache {
  public cacheStore = new Cache();
  public find(subject: string | number): any {

  }
}

export class ObjectIterator extends ObjectIteratorBase
  implements IObjectIterator {
    public data: any = {};

    constructor(obj: any) {
      super();
      this.data = obj;
    }

    public recursive = true;
}


export interface EnumEntry {
  name: string;
  value: number;
}

export class Enum {
  constructor(initData: any) {
    if (initData) {
      this.data = initData;
      this.length = this.names().length;
    }
  }

  public length = 0;

  private data: any = {};

  public validateName(name: string) {
    if (this.get(name)) {
      // tslint:disable-next-line:max-line-length
     throw new Error(`Enumerable property ${name} already exists in ${this.constructor.name}.${this.constructor.prototype.name}.data. Call {this.constructor.name}.${this.constructor.prototype.name}.prototype.forceSet(name: string, value: number) to overwrite the existing entry. Enum: ${JSON.stringify(this.data, null, 4)}.`);
    }

    return true;
  }

  public validateValue(val: number) {
    if (this.getValueByName(val)) {
      // tslint:disable-next-line:max-line-length
      throw new Error(`Enumerable value ${val} already exists in ${this.constructor.name}.${this.constructor.prototype.name}.data. Call ${this.constructor.name}.${this.constructor.prototype.name}.prototype.forceSet(name: string, value: number) to overwrite the existing entry. Enum dump: ${JSON.stringify(this.data, null, 4)}`);
    }

    return true;
  }

  public validateEntry(name: string, value: number): boolean {
    return this.validateName(name) && this.validateValue(value);
  }

  public nextImplicitEnumValue(incrementor?: number) {
    const vals: any[] = this.values().sort();

    if (vals.length > 0) {
      return vals.sort()[vals.length - 1] + 1;
    }

    return 0;
  }

  public set(name: string, value?: number): boolean {
    if (this.validateEntry(name, value)) {
      this.forceSet(name, value);
    }

    return true;
  }

  public forceSet(name: string, value: number) {
    this.data[name] = value;
    this.length = ++this.length;
  }

  public contains(name: string): boolean {
    return typeof this.data[name] !== 'undefined';
  }

  public get(name: string | number): number | boolean {
    return this.data[name] || false;
  }

  public values() {
    return Object.values(this.data);
  }

  public names() {
    return Object.keys(this.data);
  }

  public getValueByName(value: number) {
    const valueRefs = Object.values(this.data);

    if (valueRefs.includes(value)) {
      return Object.keys(valueRefs.indexOf(value));
    }

    return false;
  }
}

export class PortfolioDynamicViewModel extends PortfolioViewModelConfig implements IPortfolioViewModel {
      public portfolioTitle: string;
      public componentTitle: string;
      public navLinks: NavItemDetails[];
      public viewModel(routePath: string): any {
        const cachedRequest: any = this.getData(routePath);
        const responseData: any = cachedRequest || this.getData(routePath);
        if (!cachedRequest) {
            if (responseData) {
              this.cacheStore.set(routePath, responseData);
            } else {
              throw new Error(`No data was provided while initializing view ${routePath}. The response data was ${typeof responseData}.`);
            }
        }

        this.mutateAppStateOnRouteChange(routePath, responseData);

        return responseData;
      }

      public mutateAppStateOnRouteChange(routePath: string = 'main', viewData: any): void {
        this.componentTitle = routePath;
        this.navLinks = this.data.navLinks;
        console.log({viewData, routePath, data: this.data});
      }
}
